import React, { useEffect, useState } from 'react';
import Api from '../../api/Api';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { getContactPersons } from '../../redux/actions/azure';
import ContactPersonItem from './ContactPersonItem';
import { Grid } from '@mui/material';
import ContactPersonSkeletonList from '../skeletons/ContactPersonSkeletonList';

function ContactPersonList({selection, bigImg, skeletonCount = 3, col, showHeadlines, isSyncHeightActive = false}) {
    const {instance, accounts} = useMsal();
    const contactPersons = useSelector((state) => state.azure.contactPersons);
    const dispatch = useDispatch();
    const [headlineHeights, setHeadlineHeights] = useState([]);
    const [maxHeadlineHeight, setMaxHeadlineHeight] = useState(0);

    useEffect(() => {
        if (contactPersons.length === 0) {
            Api.acquireToken(instance, accounts[0]).then(() => {
                dispatch(getContactPersons());
            });
        }
    }, [contactPersons.length, instance, accounts, dispatch]);

    const handleHeadlineHeight = (index, height) => {
        setHeadlineHeights(() => {
            const newHeights = [...headlineHeights];

            if (newHeights[index] !== height) {
                newHeights[index] = height;

                const maxHeight = Math.max(...newHeights.filter(h => h !== undefined));

                setMaxHeadlineHeight(maxHeight);
                return newHeights;
            }

            return headlineHeights;
        });
    };

    if (contactPersons.length > 0) {
        if (selection) {
            const selectedPerson = contactPersons.filter((person) => selection.includes(person.type));

            if (selectedPerson.length > 0) {
                return (
                    <Grid container
                          spacing={2.5}
                    >
                        {selectedPerson.map((person, i) => (
                            <ContactPersonItem
                                key={i}
                                index={i}
                                person={person}
                                bigImg={bigImg}
                                col={col}
                                showHeadline={showHeadlines}
                                maxHeadlineHeight={maxHeadlineHeight}
                                setHeadlineHeight={(height) => handleHeadlineHeight(i, height)}
                                isSyncHeightActive={isSyncHeightActive}
                            />
                        ))}
                    </Grid>
                );
            }
        } else {
            return (
                <Grid container
                      spacing={1}
                >
                    {contactPersons.map((person, i) => (
                        <ContactPersonItem
                            key={i}
                            index={i}
                            person={person}
                            bigImg={bigImg}
                            col={col}
                            showHeadline={showHeadlines}
                            maxHeadlineHeight={maxHeadlineHeight}
                            setHeadlineHeight={(height) => handleHeadlineHeight(i, height)}
                            isSyncHeightActive={isSyncHeightActive}
                        />
                    ))}
                </Grid>
            );
        }
    } else {
        return (
            <ContactPersonSkeletonList count={skeletonCount} bigImg={bigImg} col={col}/>
        );
    }
}

export default ContactPersonList;
