import {
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import { NO_DATA_STRING } from '../../config/orderConfig';
import { breakpoints } from '../../theme/baseTheme';
import ProductItemDetails from './ProductItemDetails';
import { useDispatch } from 'react-redux';
import { getProductDetails } from '../../redux/actions/products';
import ProductItemDetailSkeleton from '../skeletons/ProductItemDetailSkeleton';

function ProductItem({productItem, initialOpen, orderType}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(
        window.innerWidth > breakpoints.values.md ? initialOpen : false,
    );
    const [isLoading, setIsLoading] = useState(false);

    const onAccordionChange = (ev, expanded) => {
        setIsOpen(expanded);
    };

    useEffect(() => {
        if (isOpen && !productItem.details) {
            setIsLoading(true);
            dispatch(
                getProductDetails({
                    articleNumber: productItem.articleNumber,
                    languageCode: productItem.languageCode,
                    loPath: productItem.loPath,
                }),
            ).then(() => {
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Accordion
            expanded={isOpen}
            onChange={onAccordionChange}
            sx={{
                backgroundColor: isOpen ? 'blue.100' : 'common.white',
                transition: 'background .3s ease',
                '.MuiAccordionSummary-expandIconWrapper': {alignSelf: 'start', marginTop: 1},
            }}
        >
            <AccordionSummary sx={{paddingY: 0.5, paddingX: 1}} expandIcon={<ExpandMore/>}>
                <Grid container alignItems="center" columnSpacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="subtitle1" sx={{lineHeight: '1.25'}}>
                            <>
                                <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                    {t('customerportal.product.article_number')}:&ensp;
                                </Box>
                                <Box component="span" sx={{textTransform: 'uppercase', hyphens: 'auto'}}>
                                    {productItem.articleNumber ? productItem.articleNumber : NO_DATA_STRING}
                                </Box>
                            </>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="body2">
                            <>
                                <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                    {t('customerportal.product.reference_number')}:&ensp;
                                </Box>
                                <Box component="span" sx={{textTransform: 'uppercase', hyphens: 'auto'}}>
                                    {productItem.referenceNumber ? productItem.referenceNumber : NO_DATA_STRING}
                                </Box>
                            </>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="body2">
                            <>
                                <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                    {t('customerportal.product.notation_1')}:&ensp;
                                </Box>
                                <Box component="span" sx={{textTransform: 'uppercase', hyphens: 'auto'}}>
                                    {productItem.notation1 ? productItem.notation1 : NO_DATA_STRING}
                                </Box>
                            </>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="body2">
                            <>
                                <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                    {t('customerportal.product.notation_2')}:&ensp;
                                </Box>
                                <Box component="span" sx={{textTransform: 'uppercase', hyphens: 'auto'}}>
                                    {productItem.notation2 ? productItem.notation2 : NO_DATA_STRING}
                                </Box>
                            </>
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>

            <AccordionDetails sx={{paddingTop: 0, paddingBottom: 1, paddingX: 1}}>
                {isLoading ? (
                    <ProductItemDetailSkeleton/>
                ) : (
                    <ProductItemDetails productItem={productItem}/>
                )}
            </AccordionDetails>
        </Accordion>
    );
}

export default ProductItem;
