import React, { useEffect, useRef } from 'react';
import { Paper, Grid, Avatar, Typography, Link, Box, Divider, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

function ContactPersonItem({person, bigImg, col, showHeadline, index, maxHeadlineHeight, setHeadlineHeight, isSyncHeightActive = false}) {
    const {t} = useTranslation();
    const headlineRef = useRef(null);

    useEffect(() => {
        if (isSyncHeightActive) {
            const updateHeight = () => {
                if (showHeadline && headlineRef.current) {
                    const height = headlineRef.current.clientHeight;
                    setHeadlineHeight(height);
                }
            };

            updateHeight();

            window.addEventListener('resize', updateHeight);
            return () => {
                window.removeEventListener('resize', updateHeight);
            };
        }
    }, [showHeadline, setHeadlineHeight, index, isSyncHeightActive]);

    return (
        <Grid item xs={12} md={col ? 6 : 12}>
            {showHeadline &&
                <div style={{marginBottom: '0.625rem', marginTop: 0, height: maxHeadlineHeight ? `${maxHeadlineHeight}px` : 'auto'}}>
                    <Typography
                        variant={'subtitle1'}
                        ref={headlineRef}
                    >
                        {t(`customerportal.contact_persons.type.${person.type}`)}
                    </Typography>
                </div>
            }
            <Paper elevation={1} sx={{
                paddingTop: 1.25,
                paddingBottom: 0.5,
                paddingX: 1.5,
                marginBottom: 0.625,
            }}>
                <Grid container spacing={1.25}>
                    <Grid item>
                        {person.image && (
                            <Avatar alt={person.name}
                                    src={person.image}
                                    sx={{
                                        width: {
                                            xs: 80,
                                            lg: bigImg ? 80 : 40,
                                        },
                                        height: {
                                            xs: 80,
                                            lg: bigImg ? 80 : 40,
                                        },
                                    }}
                            />
                        )}
                    </Grid>
                    <Grid item sx={{
                        flex: 1,
                        paddingBottom: person.onlineConsulting ? 0 : 0.75,
                    }}>
                        <div>
                            <Typography variant="subtitle1">{person.name}</Typography>
                            {person.role &&
                                <Typography variant="body1">
                                    {person.role}
                                </Typography>
                            }
                        </div>
                        {(person.phone || person.email) &&
                            <>
                                {person.phone &&
                                    <Typography variant="body1" sx={{
                                        marginTop: 0.5,
                                    }}>
                                        <Link href={`tel:${person.phone}`} underline="hover">{person.phone}</Link>
                                    </Typography>
                                }
                                {person.email &&
                                    <Typography variant="body1" sx={{wordBreak: "break-all"}}>
                                        <Link href={`mailto:${person.email}`} underline="hover">{person.email}</Link>
                                    </Typography>
                                }
                            </>
                        }
                    </Grid>
                </Grid>
                {person.onlineConsulting &&
                    <>
                        <Divider sx={{
                            paddingTop: 1.25,
                        }}/>
                        <Box sx={{
                            paddingTop: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Button component={Link}
                                    href={person.onlineConsulting}
                                    underline="none"
                                    target="_blank"
                                    variant="text"
                                    size="large"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        boxShadow: 0,
                                        padding: 0.5,
                                        '&:hover': {
                                            boxShadow: 0,
                                        },
                                    }}
                            >
                                <Add sx={{
                                    marginRight: 0.5,
                                }}/>
                                {t('customerportal.contact_persons.call_on_teams')}
                            </Button>
                        </Box>
                    </>
                }
            </Paper>
        </Grid>
    );
}

export default ContactPersonItem;
