import React from 'react';
import {
    Box,
    Grid,
    Skeleton,
} from '@mui/material';
import colors from '../../theme/colors';

function ProductItemDetailSkeleton() {
    return (
        <>
            <Grid container sx={{flexWrap: 'nowrap'}}>
                <Grid item xs={12} sx={{flexGrow: 1, minWidth: '100%'}}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            lg: 'row',
                        },
                        flexWrap: 'wrap',
                        gap: 0.75,
                        justifyContent: 'space-between',
                    }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={8}>
                                <Skeleton variant={'rectangular'} sx={{width: '100%', height: 45}}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Skeleton variant={'rectangular'} sx={{width: '100%', height: 90}}/>
                            </Grid>
                        </Grid>
                        <Skeleton variant={'text'} sx={{width: 200}}/>
                        <Grid container sx={{
                            width: '100%',
                            pt: 0.75,
                            borderTop: `1px solid ${colors.white}`,
                        }} spacing={0.25}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Skeleton variant={'rounded'} sx={{width: '100%', height: 45}}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton variant={'rounded'} sx={{width: '100%', height: 45}}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton variant={'rounded'} sx={{width: '100%', height: 45}}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>

            </Grid>

        </>

    );
}

export default ProductItemDetailSkeleton;
